import React, { useCallback } from "react";
import { Datagrid, DateField, List, SelectField, TextField, useRedirect } from "react-admin";

import { PendingTask } from "../api/pending_tasks";
import { createPendingTaskLink } from "../utils/url";
import { createTaskKeys } from "../components/choices";

export const PendingTaskList = (props: any) => {
  const redirect = useRedirect();

  const rowClick = useCallback(
    (id: number, basePath: string, record: PendingTask) => {
      const path = createPendingTaskLink(record);
      redirect(path);
    },
    [redirect]
  );

  return (
    <List {...props} bulkActionButtons={false} exporter={false} sort={{ field: "deadline", order: "ASC" }}>
      <Datagrid rowClick={rowClick}>
        <TextField source="id" />
        <SelectField source="task_key" choices={createTaskKeys()} sortable={false} />
        <DateField source="deadline" />
        <TextField source="trading_partner" sortable={false} />
        <TextField source="description" sortable={false} />
      </Datagrid>
    </List>
  );
};
