import React from "react";
import { useTranslate } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { DateTime } from "luxon";

type Props = {
  source: "inspection_adjustment_status";
  record?: {
    inspection_adjustment_status: string;
    inspection_adjustment_at: string;
  };
  sortable?: boolean;
  showTime?: boolean;
};

export const InspectionAdjustmentStatusField = (props: Props) => {
  const translate = useTranslate();

  const { record, showTime } = props;
  if (!record) {
    return null;
  }

  const { inspection_adjustment_at, inspection_adjustment_status } = record;

  const text = translate(`resources.deals.fields.inspection_adjustment_statuses.${inspection_adjustment_status}`);

  if (showTime === true) {
    const time = DateTime.fromISO(inspection_adjustment_at);
    return (
      <Typography variant="body2">
        {text}
        {time.isValid && (
          <Typography variant="caption" display="inline" style={{ marginLeft: "1em" }}>
            {time.toFormat("yyyy/LL/dd HH:mm:ss")}
          </Typography>
        )}
      </Typography>
    );
  }

  return <Typography variant="body2">{text}</Typography>;
};

InspectionAdjustmentStatusField.defaultProps = {
  addLabel: true,
};
